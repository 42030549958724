import * as React from "react";
import { FormattedMessage, Link } from "gatsby-plugin-react-intl";
import Layout from "@components/Layout";
import NoResult from "@components/NoResult";
import "./index.sass"

const NotFoundPage = () => (
  <Layout isHeaderFixed isFooterFixed>
    <div className="notfound-page" >
      <div className="notfound-icon">
        <NoResult />
      </div>
      <div className="notfound-text">
        <h1 className="notfound-title">404</h1>
        <span className="notfound-body">
          <FormattedMessage id="404_CAPTION" />
        </span>
        <Link to="/" className="notfound-home">
          <FormattedMessage id="404_CAPTION_HOME" />
        </Link>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
